import { CardContent, Box, Modal, IconButton } from "@mui/material";
import ProjectCard from "./ProjectCard";
import ProjectTitleBox from "./ProjectTitleBox";
import DetailedImagesThumbButton from "./DetailsImagesThumbButton";
import styled from "@emotion/styled";
import Grid from '@mui/material/Unstable_Grid2';

// Magenta overlay - rgba(255, 140, 209, 0.1)
// Cyan overlay - rgba(126, 216, 255, 0.1)
// Yellow overlay - rgba(255, 213, 104, 0.1)

const StyledProjectCard = styled(ProjectCard)`
  position: relative;
  background: #FFF;
  background: ${props => props.previewImage ? `url(${props.previewImage})` : `url("https://placehold.co/250")` };
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0;
  border-radius: 0;
  max-height: 300px;
  opacity:  0;
  animation-delay: ${props => props.isMenuClicked ? `${300 + ((props.index + 1) % 4) * 30}ms` : `${500 + ((props.index + 1) % 4) * 30}ms` };
  box-shadow:  none;
  justify-content: center;
  /* 
  box-shadow:  1.5px 1.5px rgba(0, 0, 0, 0.3);
  border-image: linear-gradient(to bottom, rgba(255, 140, 209, 1), rgba(255, 255, 255, 1)) 1;
  border-width: 3px;
  border-style: solid;
  
  div:hover {
    background-color: red;
  }
  */

  &::before {
    content: '';
    background: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    width: 400px;
    height: 300px;
    position: absolute;
    transition: all ease-in-out 400ms;
  }

  transition: transform ease-in-out 200ms;

  &:hover {
    transform: translate(0, -3px);
    background: ${props => props.detailedImages[1] ? `url(${props.detailedImages[1][1]})` : `url("https://placehold.co/250")` };
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &::before {
      // background: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    }
  }

  @media (max-width: 420px) {
    .MuiCardContent-root {
      padding: 1px;
    }
  }

  @media (max-width: 1535px) {
    animation-delay: ${props => (`${((props.index + 1) % 3) * 30}ms`)};
  }

  @media (max-width: 1199px) {
    animation-delay: ${props => (`${((props.index + 1) % 2) * 30}ms`)};
  }
`;

const StyledCardContent = styled(CardContent)`
  display: flex;
  align-items: end;
  flex-direction: row;
  min-height: 300px;
  min-width: 300px;
  max-height: 300px;
`;

const StyledTag = styled.span`
  background-color: ${props => props.mainTag ? '#FFD568' : '#7ED8FF'};
  background: ${props => props.mainTag ? 'linear-gradient(to right bottom, #FFDD86, #FFD568)' : 'linear-gradient(to right bottom, #98E0FF, #7ED8FF)'};
  margin: ${props => props.description ? '0 0.5em 0.5em 0' : '0.5em 0.5em 0 0'};
  padding: 0.3em;
  box-shadow: 1.5px 1.5px rgba(0, 0, 0, 0.3);
  font-family: leagueSpartan;
  font-size: 14px;
  font-weight: 400;
`;

const StyledTitleTag = styled.h5`
  background-color: #FF8CD1;
  background: linear-gradient(to right bottom, #FFA3DA, #FF8CD1);
  margin-top: 0.5em;
  padding: 0.4em;
  min-width: 100%;
  opacity: ${props => false ? '100' : '0'};
  animation:  ${props => false ? 'none' :'fadeIn 400ms forwards'};
  animation-delay: ${props => props.isMenuClicked ? (`${200 + (props.index + 1) * 30}ms`) : (`${800 + (props.index + 1) * 30}ms`)};
  box-shadow: 1.5px 1.5px rgba(0, 0, 0, 0.3);
  font-family: leagueSpartan;
  font-size: 22px;
  font-weight: 400;
  /* text-shadow:  1px 1px rgba(0, 0, 0, 0.08);
  color: #333333; */
`;

const StyledTagsDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: ${props => props.description ? '0' : '283px'};
  opacity: ${props => props.description ? '100' : '0'};
  animation:  ${props => props.description ? 'none' :'fadeIn 400ms forwards'};
  animation-delay: ${props => props.isMenuClicked ? (`${150 + (props.index + 1) * 30}ms`) : (`${750 + (props.index + 1) * 30}ms`)};
  margin-bottom: ${props => props.description ? '1em' : '0'};
`;

const StyledProjectTitleBox = styled(ProjectTitleBox)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 345px;

  @media (max-width: 1199px) {
    padding: 0.5em;
  }

  @media (max-width: 899px) {
    min-width: 250px;
    padding: 0;
  }

  @media (max-width: 520px) {
    min-width: none;
    width: auto;
  }
`;

const StyledPlaceholderDiv = styled.div`
  display: flex;
  background-color: white;
  margin-bottom: 5px;
  align-self: stretch;
  flex: auto;
  flex-direction: column;
  position: relative;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.2);
`;

const StyledImageDiv = styled.div`
  background-color: white;
  margin: 5px 0;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 720px) {
    height: 350px;
  }
`;

const StyledZoomMessage = styled.p`
  margin: 2em 0 0 0;
`;

const StyledMainImage = styled.img`
  width: 85%;
  opacity: 0;
  animation: fadeIn 400ms forwards;
  animation-delay: 150ms;

  @media (max-width: 720px) {
    width: 95%;
  }
`;

const StyledDetailsDiv = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  //width: 85vw;
  transform: translate(-50%, -50%);
  background-color: transparent;
  display: flex;
  flex-direction: row;
  /* align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh; */
  max-height: 100vh;
  margin-top: 1vh;

  @media (max-width: 899px) {
    margin-top: 5vh;
  }
`;

const StyledDetailsGrid = styled(Grid)`
  opacity: 0;
  animation: fadeIn 600ms forwards;
  width: 75vw;
`;

const StyledModal = styled(Modal)`
  overflow-y: auto;
  overflow-x: hidden;

  & .MuiBackdrop-root {
    transition: none !important;
  }
`;

const StyledGridItem = styled(Grid)`
  display: flex;
`;

const StyledDetailsTitle = styled.div`
  font-size: 22px;
  background: ${props => props.description ? 'linear-gradient(to right bottom, #333333, #000000)' : 'linear-gradient(to right bottom, #FFA3DA, #FF8CD1)' };
  color: ${props => props.description ? '#FFFFFF' : '#000000'};
  padding: 0.5em;
  box-shadow: 0 1.5px rgba(0, 0, 0, 0.3);
  justify-content: space-between;
  display: flex;
`;

const StyledDescriptionDiv = styled.div`
  padding: 1.5em;
  padding-top: 0.5em;
  margin: 0.5em 1em 0.5em 0.5em;
  min-height: 483px;
  max-height: ${props => props.link ? '538px' : '590px'};
  overflow-y: auto;
  font-size: 17px;

  ::-webkit-scrollbar-thumb {
    background: linear-gradient(to right bottom, #FFDD86, #FFD568);
    box-shadow: 0 1px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: linear-gradient(to bottom, #FFFFFF 80%, #D4D4D4);
  }

  @media (max-width: 1425px) {
    max-height: ${props => props.link ? '575px' : '600px'};
  }

  @media (max-width: 899px) {
    max-height: none;
    padding: 0.5em 1em 1em 1em;
  }
`;

const StyledStepperDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1em;
`;

const StyledExitButton = styled(IconButton)`
  display: ${props => props.wide === 1 ? 'flex' : 'none'};
  border-radius: 0;
  
  @media (max-width: 899px) {
    display: ${props => props.skinny === 1 ? 'flex' : 'none'};
  }
`;

const StyledArrowButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99;
`;

const StyledDetailsImagesThumbButton = styled(DetailedImagesThumbButton)`
  position: relative;
  background: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  margin: 0.3em 0 0.5em 0;
  border-radius: 0;
  border-bottom: ${props => props.selectedThumb ? 'solid 3px #7ED8FF' : 'solid 3px rgba(255, 255, 255, 0)'};
  transform: ${props => props.selectedThumb ? 'translate(0, -3px)' : 'none' };
  transition: all ease-in-out 200ms;
`;

const StyledDetailsImagesThumbImg = styled.img`
  height: 70px;
  width: 70px;
  opacity: 0.6;
  object-fit: cover;
  object-position: center;
`;

const StyledThumbsDiv = styled.div`
  display: flex;
  overflow-x: auto;

  ::-webkit-scrollbar-thumb {
    background: linear-gradient(to right bottom, #FFDD86, #FFD568);
    box-shadow: 0 1px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar {
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: linear-gradient(to right, #FFFFFF 80%, #D4D4D4);
  }
`;

const StyledAnchorLink = styled.a`
  color: black;
  opacity: 0;
  animation: fadeIn 400ms forwards;
  animation-delay: ${props => (`${(props.index + 1) * 100}ms`)};
  background: linear-gradient(to right bottom, #98E0FF, #7ED8FF);
  border: none;
  height: 2em;
  width: 8em;
  text-align: center;
  align-items: center;
  font-size: 20px;
  box-shadow: 1.5px 1.5px rgba(0, 0, 0, 0.3);
  padding-top: 4px;
  transition: all ease-in-out 200ms;
  text-decoration: none;

  &:hover {
    transform: translate(0, -3px);
    box-shadow: 1.5px 3px rgba(0, 0, 0, 0.2);
  }
`

const StyledAnchorDiv = styled.div`
  display: flex;
  margin: 8.5px 1em 17px 0.5em;
  justify-content: center;
`;

const StyledVideoEmbed = styled.iframe`
  width: 90%;
  height: 90%;
  opacity: 0;
  animation: fadeIn 400ms forwards;
  animation-delay: 150ms;
`;

export {
  StyledProjectCard,
  StyledCardContent,
  StyledTag,
  StyledTitleTag,
  StyledProjectTitleBox,
  StyledTagsDiv,
  StyledPlaceholderDiv,
  StyledDetailsDiv,
  StyledImageDiv,
  StyledGridItem,
  StyledDetailsTitle,
  StyledDescriptionDiv,
  StyledModal,
  StyledDetailsGrid,
  StyledStepperDiv,
  StyledMainImage,
  StyledZoomMessage,
  StyledExitButton,
  StyledArrowButtonDiv,
  StyledDetailsImagesThumbButton,
  StyledAnchorLink,
  StyledAnchorDiv,
  StyledVideoEmbed,
  StyledThumbsDiv,
  StyledDetailsImagesThumbImg
};
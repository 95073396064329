import styled from "@emotion/styled";
import AboutGridItem from "./AboutGridItem";
import MyPortrait from "./me_blue_shirt.JPG";

const StyledAboutDiv = styled.div`
  padding: 1em;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledAboutTitle = styled.h2`
  font-size: 4em;
  display: flex;
  flex-direction: row;
  margin: 0.9em 0 0.5em 0;

  @media (max-width: 899px) {
    margin: 0 0 0.5em 0;
  }
`;

const StyledPlaceholderImg = styled.div`
  min-width: 100%;
  min-height: 360px;
  width: 100%;
  height: 100%;
  background: ${`url(${MyPortrait})`};
  background-size: cover;
  background-position: 60% 0;
  background-repeat: no-repeat;
  //justify-self: end;

  &::before {
    content: '';
    background: linear-gradient(to right, rgba(255, 255, 255, 0.15) 76%, rgba(255, 255, 255, 1) 90%);
    position: absolute;
    transition: all ease-in-out 400ms;
    min-width: 100%;
    min-height: 360px;
    width: 100%;
    height: 100%;

    @media (max-width: 899px) {
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 1) 90%);
    }
    
    @media (max-width: 599px) {
      min-width: 75vw;
    }
  }

  @media (max-width: 899px) {
    background-position: center;
  }

  @media (max-width: 599px) {
    min-width: 76vw;
  }
`;

const StyledHeaderText = styled.h3`
  font-size: ${props => `${props.fontSize}em`};
`;

const StyledIntroDiv = styled.div`
  margin-top: auto;
`;

const StyledAboutGridItem = styled(AboutGridItem)`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: ${prop => prop.move};
  margin-bottom: 1.5em;
  opacity: 0;
  animation-delay: ${props => props.isMenuClicked || !props.alwaysAppear ? `${((props.index + 1) % 4) * 100}ms` : `${600 + (((props.index + 1) % 4) * 100)}ms` };
`;

const StyledDivider = styled.div`
  height: 0.5em;
  width: 100%;
  background: linear-gradient(to right, #FFD568,  rgba(255, 255, 255, 0));
  margin: 3em 0 4em 0;
`;

const StyledSkillItem = styled.div`
  display: flex;
  background: ${props => props.colorDark ? `linear-gradient(to right, ${props.colorDark}, rgba(255, 255, 255, 0) 95%)` : 'linear-gradient(to right, #FFD568, rgba(255, 255, 255, 0) 95%)'};
  min-height: 3.5em;
  width: 90%;
  align-items: center;
  padding: 0.5em;
  margin: 0 1em 0.5em 0;
  transition: all ease-in-out 200ms;

  &:hover {
    transform: translate(-5px, 0);
  }
`

const StyledSpan = styled.span`
  font-size: ${props => props.fontSize ? `${props.fontSize}em` : '1em'};
  padding-left: ${props => props.listItem ? '1em' : 0};
  position: relative;

  ${props => props.dot ?
  `&::before {
    content: '';
    position: absolute;
    left: 0.1em;
    top: 0.1em;
    height: 0.5em;
    width: 0.5em;
    background-color: #FFD568;
  }`
  : null
  }
`;

const GridDiv = styled.div`
  display: flex;
  flex: wrap;
  flex-direction: column;

  @media (max-width: 1535px) {
    flex-direction: row;
  }

  @media (max-width: 899px) {
    flex-direction: column;
  }
`;

const GridDivUnit = styled.div`
  display: flex;
  flex: wrap;
  flex-direction: column;
  margin-bottom: 2em;

  p {
    margin: 0;
  }

  @media (max-width: 1535px) {
    width: 50%;
  }

  @media (max-width: 899px) {
    width: auto;
  }
`;

const TechDiv = styled.div`
  display: flex;
  flex: wrap;
  flex-direction: row;
`;

const TechDivUnit = styled.div`
  display: flex;
  flex: wrap;
  flex-direction: column;
  width: 50%;
`;

export {
  StyledAboutDiv,
  StyledAboutTitle,
  StyledPlaceholderImg,
  StyledHeaderText,
  StyledIntroDiv,
  StyledAboutGridItem,
  StyledDivider,
  StyledSkillItem,
  StyledSpan,
  GridDiv,
  GridDivUnit,
  TechDiv,
  TechDivUnit
};